import React,{useState,useEffect} from "react";


export function ActivoVerVehiculo(propiedades){
    const [vehiculo,setVehiculo]=useState(propiedades.vehiculo?? null);
    const [tarjeta,setTarjeta]=useState(propiedades.tarjeta??null);


    if(vehiculo==null){
        return(<div></div>);
    }

    return(
        <div className="">
        <h4 className="mb-3 fw-normal">Vehiculo</h4>
        <div className="card">
            <div className="card-header">
                <div className="row">
                    
                <label className="form-label">Tarjeta de Propiedad</label>
                    <div className="row">
                    <div className="col-md-4">
                    <label className="form-label">Largo</label>
                    <label className="form-control">{tarjeta.largo}</label>
                    </div>

                    <div className="col-md-4">
                    <label className="form-label">Ancho</label>
                    <label className="form-control">{tarjeta.ancho}</label>
                    </div>

                    <div className="col-md-4">
                    <label className="form-label">Alto</label>
                    <label className="form-control">{tarjeta.alto}</label>
                    </div>



                    <div className="col-md-4">
                    <label className="form-label">Peso Bruto</label>
                    <label className="form-control">{tarjeta.pesobruto}</label>
                    </div>

                    <div className="col-md-4">
                    <label className="form-label">Peso Neto</label>
                    <label className="form-control">{tarjeta.pesoneto}</label>
                    </div>

                    <div className="col-md-4">
                    <label className="form-label">Carga Util</label>
                    <label className="form-control">{tarjeta.pesobruto}</label>
                    </div>
                        <br/>
                        <br/>
                    </div>
                    


                    <div className="col-md-6">
                    <label className="form-label">Placa de rodaje</label>
                    <label className="form-control">{vehiculo.placa}</label>
                    </div>

                    <div className="col-md-6">
                    <label className="form-label">Año de fabricacion</label>
                    <label className="form-control">{vehiculo.aniofab}</label>
                    </div>

                    <div className="col-md-6">
                    <label className="form-label">Color</label>
                    <label className="form-control">{vehiculo.color}</label>
                    </div>
                    <div className="col-md-6">
                    <label className="form-label">Marca</label>
                    <label className="form-control">{vehiculo.marca}</label>
                    </div>

                    <div className="col-md-6">
                    <label className="form-label">Modelo</label>
                    <label className="form-control">{vehiculo.modelo}</label>
                    </div>

                    <div className="col-md-6">
                    <label className="form-label">Carrocería</label>
                    <label className="form-control">{vehiculo.carroceria}</label>
                    </div>

                    <div className="col-md-6">
                    <label className="form-label">Categoría</label>
                    <label className="form-control">{vehiculo.categoria}</label>
                    </div>

                    <div className="col-md-6">
                    <label className="form-label">Ejes</label>
                    <label className="form-control">{vehiculo.eje}</label>
                    </div>

                    <div className="col-md-6">
                    <label className="form-label">Serie de chasis</label>
                    <label className="form-control">{vehiculo.seriechasis}</label>
                    </div>

                    <div className="col-md-6">
                    <label className="form-label">Serie y marca de motor</label>
                    <label className="form-control">{vehiculo.motor} </label>
                    </div>

                    <div className="col-md-6">
                    <label className="form-label">Zona Registral</label>
                    <label className="form-control">{vehiculo.zonaregistral} </label>
                    </div>

                    <div className="col-md-6">
                    <label className="form-label">Radio de comunicación</label>
                    <label className="form-control">{vehiculo.radiobase}</label>
                    </div>
                    <div className="col-md-6">
                    <label className="form-label">GPS</label>
                    <label className="form-control">{vehiculo.gps}</label>
                    </div>








                    









                    
                    </div>
                    
            </div>
            <br/>
                    
        </div>
    </div>                             

    );


}