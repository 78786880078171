import React, { useState } from "react";
import {Link,useNavigate, useSearchParams} from "react-router-dom";

export function ActivoRegistro(){
    const navigate = useNavigate();
    const[params] = useSearchParams();
    const idactivo = params.get('idactivo')?? '';
    
    //propiedades
    const[codigo,setCodigo] = useState(params.get('codigo') ?? '');
    const[tipo,setTipo] = useState(params.get('tipo')??''); 
    const[ubicacion,setUbicacion] = useState("");
    const[descripcion,setDescripcion] = useState("");

    const onCodigoChange = e => setCodigo(e.target.value);
    const onTipoChange = e => setTipo(e.target.value);
    const onUbicacionChange = e => setUbicacion(e.target.value);
    const onDescripcionChange = e => setDescripcion(e.target.value);

    const handleGuardarActivo = e =>{
        e.preventDefault();

        const data = {idactivo,codigo,ubicacion,tipo,descripcion};
        console.log(data);
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(data)
        };
        fetch(process.env.REACT_APP_INVENTARIO_API_URL+ "api/activos/update/"+idactivo,requestOptions)
        .then(response => response.json())
        .then(res =>{
            console.log(res);
            if(res.idactivo>0){
                console.log("Se registro el activo");
                console.log(res);
                
                navigate('/activo/?idactivo='+idactivo+'&tipo='+tipo+'&codigo='+codigo);

            }else
            {
                console.log("acceso incorrecto vuelve a intentar");
                
            }


        });
    

    }

    return(
        <div className="container ActivoRegistro">
            <br/>
            <h3 className="mb-3 text-center">Registro de activo</h3>
            <br/>
            

            <div>
            <h4>Id Activo: {idactivo}</h4>
            <div className="text-end">
                <Link to ={`/activo/?idactivo=${idactivo}&tipo=${tipo}&codigo=${codigo}`} className="btn btn-light"><i className="bi bi-arrow-bar-left"></i>Regresar</Link>
            </div>
            
            </div>
            
            <div className="row g-5">
                <form className="needs-validation was-validated">
                <div className="row g-3">
                    <div className="col-md-5">
                    <label className="form-label">Código</label>
                    <input className="form-control" placeholder="código" value={codigo} onChange={onCodigoChange} required/>
                    </div>

                    <div className="col-md-4">
                    <label className="form-label">Ubicacion</label>
                    <input className="form-control" placeholder="ubicación" value={ubicacion} onChange={onUbicacionChange} required/>
                    </div>

                    <div className="col-md-3">
                    <label className="form-label">Tipo</label>
                    <input type="text" className="form-control"  placeholder="tipo activo" value={tipo} onChange={onTipoChange} required/>
                    </div>
                    
                    <div className="col-12">
                    <label className="form-label">Descripción</label>
                    <input type="text" className="form-control" placeholder="descripción de activo" value={descripcion} onChange={onDescripcionChange} required/>
                    </div>
                </div>

                <div className="text-end">
                <br/>
                <button className="col-12 col-md-6 col-lg-4 btn btn-primary" onClick={handleGuardarActivo}>Guardar</button>
                </div>
                </form>
            
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
        </div>        
    );
}