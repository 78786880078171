import React,{useState} from "react";
import { useEffect } from "react";
import { Link,useNavigate, useSearchParams} from "react-router-dom";
import { ActivoVerVehiculo } from "./activovervehiculo";






export function ActivoVer(){
    const[params] = useSearchParams();
    const[activo,setActivo]=useState(null);

    const [codigo,setCodigo] = useState(params.get('codigo') ?? '');
    const idactivo = params.get('idactivo')?? '';
    const [tipo,setTipo] = useState(params.get('tipo')??'');
    
    const [cargando,setCargando] = useState(true);
    const [vehiculo,setVehiculo]=useState(null);
    const [tarjeta,setTarjeta]=useState(null);

    useEffect(()=>{
        const requestOptions = {
            method: "GET",
            headers: {"Content-Type": "application/json"}
        };
            fetch(process.env.REACT_APP_INVENTARIO_API_URL+"api/activos/show/"+idactivo,requestOptions)
            .then(response => response.json())
            .then(res =>{
                
                if(res.rest==1){
                    setActivo(res.activo);
                    setTipo(res.activo.tipo);
                    console.log(res);
                    if(res.activo.tipo=='flota'){
                        setVehiculo(res.activo.vehiculo);
                        if(res.activo.vehiculo.tarjetapropiedad !== null){
                            setTarjeta(res.activo.vehiculo.tarjetapropiedad);
                        }
                    }
                    setCargando(false);
                }
            
            });
    },[]);

    if(cargando){
        return(
            <div className="container"><h4 className="text-center">Cargando...</h4></div>
        );
    }


    return(
        <div className="container">
            <br/>
            <h3 className="mb-3 text-center">Información</h3>
            <br/>
            
            <div>
                <div className="text-end">
                    <Link to ={`/activo/?idactivo=${idactivo}&tipo=${tipo}&codigo=${codigo}`} className="btn btn-light">Regresar</Link>
                </div> 
                <h4 className="mb-3 fw-normal">Id Activo: {idactivo} - código: {activo.codigo}</h4>
                <div className="card" >
                    <div className="card-header">
                        <h5>Datos del activo</h5>
                        Descripcion: {activo.descripcion}<br/>
                        Tipo: {activo.tipo}<br/>                                                   
                    </div>
                </div>

            </div>

            <br/>

            <ActivoVerVehiculo vehiculo={vehiculo} tarjeta={tarjeta}/>            

            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>




        </div>
    )

}