import React,{useEffect,useState} from "react";

export function InventarioInicio(propiedades){
    const [cargado,setCargado]=useState(true);
    const [inventario,setInventario]= useState(null);

    useEffect(()=>{
        const requestOptions = {
            method: "GET",
            headers: {"Content-Type": "application/json"}
        };
        fetch(process.env.REACT_APP_INVENTARIO_API_URL +"api/inventario/obtenerInventarioActualxidActivo/"+propiedades.idactivo,requestOptions)
        .then(response => response.json())
        .then(res => {
            if(res.rest==1){
                setInventario(res);
                setCargado(false);
            }    
        });

    },[]);




    if(cargado){
        return(<div></div>);
    }
    return(
        <div>
            <div className="alert alert-success" role="alert">
            ¡Inventariado! Descripcion: {inventario.rest==0? '':inventario.inventario.observacion}<br/>
                    
            Realizado por: {inventario.rest==0?'': inventario.inventario.usuario==null?'': inventario.inventario.personal.nombre} 
            </div>
        </div>

    );
    
}