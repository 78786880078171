import React,{useState} from "react";
import {json, useNavigate, useSearchParams} from "react-router-dom";
import { useEffect } from "react";
import { InventarioInicio } from "./inventarioinicio";
import { VehiculoInicio } from "./vehiculoinicio";




export function Inicio(){
    const navigate = useNavigate();
    const[params] = useSearchParams();

    //parametros
    const [codigo,setCodigo] = useState(params.get('codigo') ?? '');
    const idactivo = params.get('idactivo')?? '';
    const [tipo,setTipo] = useState(params.get('tipo')??'');    
    
    const[clave,setClave]=useState("");
    const[mensaje,setMensaje] = useState("");
    const [activo,setActivo]=useState(null);
    const [tipovehiculo,setTipoVehiculo]=useState(null);

    const onClaveChange = e => setClave(e.target.value);
    
    const [cargando,setCargando] = useState(true);
    useEffect(()=>{
        const requestOptions = {
            method: "GET",
            headers: {"Content-Type": "application/json"}
        };
            fetch(process.env.REACT_APP_INVENTARIO_API_URL+"api/activos/show/"+idactivo,requestOptions)
            .then(response => response.json())
            .then(res =>{
                setActivo(res);
                if(res.rest==1){
                    setCodigo(res.activo.codigo);
                    setTipo(res.activo.tipo);
                    console.log(res);
                    if(res.activo.tipo=='flota'){
                        if(res.activo.vehiculo!=null){
                            setTipoVehiculo(res.activo.vehiculo.idcarroceria);
                        }else{
                            setTipoVehiculo(23); //por defecto coo remolque
                        }
                    }
                    setCargando(false);
                }
            
            });
    },[]);

    const handleInventarioFlota = e =>{
        e.preventDefault();

        const data = { codigo,clave };
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(data)
        };
        fetch(process.env.REACT_APP_INVENTARIO_API_URL+"api/personal/validar",requestOptions)
        .then(response => response.json())
        .then(res =>{
            console.log(res);
            if(res.respuesta==1){
                setMensaje("");
                navigate('/activo/inventario/?idactivo='+idactivo+'&tipo='+tipo+'&codigo='+codigo+'&idpersonal='+res.personal.idpersonal+'&tipovehiculo='+tipovehiculo);
            }else
            {
                setMensaje("Error de acceso, ingrese una clave correcta. ");
            }
        });

    };

    const handleVerActivo = e =>{
        e.preventDefault();
        
        const data = { codigo,clave };
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(data)
        };
        fetch(process.env.REACT_APP_INVENTARIO_API_URL+ "api/personal/validar",requestOptions)
        .then(response => response.json())
        .then(res => {
            console.log(res);
            if(res.respuesta=1){
                console.log("acceso correcto ver activo");
                setMensaje("");
                navigate('/activo/ver/?idactivo='+idactivo+'&tipo='+tipo+'&codigo='+codigo);
            }else
            {
                console.log("acceso incorrecto vuelve a intentar");
                setMensaje("Error de acceso, ingrese una clave correcta. ");
            }
        });
    };

    const handleRegistrarActivo = e =>{
        const data = { codigo,clave };
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(data)
        };
        fetch(process.env.REACT_APP_INVENTARIO_API_URL+"api/personal/validar",requestOptions)
        .then(response => response.json())
        .then(res => {
            if(res.respuesta==1){
                setMensaje("");
                navigate('/activo/registro/?idactivo='+idactivo+'&tipo='+tipo+'&codigo='+codigo);
            }else
            {
                setMensaje("Error de acceso, ingrese una clave correcta. ");
                
            }
        });      
    }

    if(cargando){
        return(
            <div className="container"><h4 className="text-center">Cargando...</h4></div>
        );
    }
    return (
        <div className="container Inicio"><br/>
            <h3 className="text-center">Inventario de activos</h3>
            <br/>
            <div>
                <h1 className="h3 mb-3 fw-normal">Id Activo: {idactivo} - Código: {codigo}</h1>
                <div className="card" >
                    <div className="card-header">
                    <h6>Datos del activo</h6>
                        Descripcion: {activo.activo.descripcion}<br/>
                        Tipo: {activo.activo.tipo}<br/>
                        <br/>
                        <VehiculoInicio activo={activo}></VehiculoInicio>                              
                    </div>
                </div>
                <br/>
                <InventarioInicio idactivo={idactivo}></InventarioInicio>

                <br/>
           
                <div className="form-floating">
                    <input placeholder="Clave" value={clave} onChange={onClaveChange} className="form-control" required /> 
                    <label className="form-label">Ingrese la clave: </label>
                </div>
                <br/>
                <div className="alert alert-danger" role="alert" hidden={mensaje==""?1:0}>
                    {mensaje}
                </div>
                        
                <div className="row g-3">

                <div className="col-md-4">
                <button type="button" className="btn btn-primary col-12" onClick={handleInventarioFlota} > Inventario</button>
                </div>

                <div className="col-md-4">
                <button type="button" className="btn btn-success col-12" onClick={handleVerActivo} >Ver</button>
                </div>

               
                
                <div className="col-md-4" hidden={tipo=='general'?0:1}>
                <button type="button" className="btn btn-warning col-12" onClick={handleRegistrarActivo} >Registrar</button>
                </div>
                
                </div>

                
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
        </div>
    );
}