import React,{useState,useEffect} from "react";

export function VehiculoInicio(propiedades){
    const [vehiculo,setVehiculo]=useState(propiedades.activo.activo.vehiculo?? null);
    
    if(vehiculo==null){
        return(<div></div>);
    }

    return(
                <div>
                    <h6>Datos del vehiculo</h6>
                    Año de fabricación: {vehiculo.aniofab}<br/>
                    Color: {vehiculo.color}     
                </div>                               

    );
}