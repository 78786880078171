import React,{useState,useEffect} from "react";
import {Link,useNavigate, useSearchParams} from "react-router-dom";

export function Inventario(){
    const navigate = useNavigate();
    const[params] = useSearchParams();
    
    //
    const [imagen1,setImagen1]=useState(null);
    const [imagen2,setImagen2]=useState(null);
    const [imagen3,setImagen3]=useState(null);
    const [imagen4,setImagen4]=useState(null);

    const [urlimagen1,setUrlImagen1]=useState('https://w7.pngwing.com/pngs/527/625/png-transparent-scalable-graphics-computer-icons-upload-uploading-cdr-angle-text.png');
    const [urlimagen2,setUrlImagen2]=useState('https://w7.pngwing.com/pngs/527/625/png-transparent-scalable-graphics-computer-icons-upload-uploading-cdr-angle-text.png');
    const [urlimagen3,setUrlImagen3]=useState('https://w7.pngwing.com/pngs/527/625/png-transparent-scalable-graphics-computer-icons-upload-uploading-cdr-angle-text.png');
    const [urlimagen4,setUrlImagen4]=useState('https://w7.pngwing.com/pngs/527/625/png-transparent-scalable-graphics-computer-icons-upload-uploading-cdr-angle-text.png');

    const onSelectImagen1 = e =>{
        setUrlImagen1(URL.createObjectURL(e.target.files[0]));
        setImagen1(e.target.files[0]);
    } 

    const onSelectImagen2 = e =>{
        setUrlImagen2(URL.createObjectURL(e.target.files[0]));
        setImagen2(e.target.files[0]);
    }

    const onSelectImagen3 = e =>{
        setUrlImagen3(URL.createObjectURL(e.target.files[0]));
        setImagen3(e.target.files[0]);
    }
    const onSelectImagen4 = e =>{
        setUrlImagen4(URL.createObjectURL(e.target.files[0]));
        setImagen4(e.target.files[0]);
    }
    //parametros
    const codigo = params.get('codigo') ?? '';
    const idactivo = params.get('idactivo')?? '';
    const tipo = params.get('tipo')??'';   
    const usuario = params.get('idpersonal')??'';
    const tipovehiculo=params.get('tipovehiculo')?? 0;

    const [periodo,setPeriodo] = useState(null);
    const[nombreperiodo,setNombrePeriodo] = useState(null); 
    const [observacion,setObservacion] = useState("");
    
    const [inventario,setInventario] = useState(null);
    const [cargando,setCargando] = useState(true);
    
    //planilla datos
    const [tipoplanilla,setTipoPlanilla] = useState('')
    const [planilla,setPlanilla] = useState(null);

    const camion=['1','5','6','7','8','9'];
    const remolcado=['23'];
    const semiremolque=['19','20','21','22','24'];

    useEffect(()=>{
        const camposplanilla=[
            {nombre:'tarjetapropiedad',etiqueta:'Tarjeta de propiedad ',valor:false,campocomentario:'tarjetapropiedadcom', comentario:'',visible:true},
            {nombre:'tarjetamercancias',etiqueta:'Tarjeta de mercancías ' ,valor:false,campocomentario:'tarjetamercanciascom', comentario:'',visible:true },
            {nombre:'soat',etiqueta:"SOAT ",valor:false,campocomentario:'soatcom', comentario:'',visible:true},
            {nombre:'revisiontecnica12',etiqueta:"Revisión técnica 12 meses ",valor:false,campocomentario:'revisiontecnica12com', comentario:'',visible:true},
            {nombre:'revisiontecnica6',etiqueta:"Revisión técnica 6 meses ",valor:false,campocomentario:'revisiontecnica6com', comentario:'',visible:true},
            {nombre:'resoluciondirectoral',etiqueta:"Resolución Directoral ",valor:false,campocomentario:'resoluciondirectoralcom', comentario:'',visible:true},
            {nombre:'resoluciongerencial',etiqueta:"Resolución Gerencial ",valor:false,campocomentario:'resoluciongerencialcom', comentario:'',visible:true},
            {nombre:'iqbf',etiqueta:"IQBF ",valor:false,campocomentario:'iqbfcom', comentario:'',visible:true},
            {nombre:'certificadomatpel',etiqueta:"Certificado Matpel ",valor:false,campocomentario:'certificadomatpelcom', comentario:'',visible:true},
            {nombre:'certificadobonificacion',etiqueta:"Certificado bonificación ",valor:false,campocomentario:'certificadobonificacioncom', comentario:'',visible:true},
            {nombre:'certificadooperatividad',etiqueta:"Certificado de operatividad ",valor:false,campocomentario:'certificadooperatividadcom', comentario:'',visible:true},

            {nombre:'placarodaje',etiqueta:'Placa de rodaje',valor:false,campocomentario:'placarodajecom', comentario:'',visible:true},
            {nombre:'aniofabricacion',etiqueta:' Año de fabricación',valor:false,campocomentario:'aniofabricacioncom', comentario:'',visible:true},
            {nombre:'color',etiqueta:'Color',valor:false,campocomentario:'colorcom', comentario:'',visible:true},
            {nombre:'marca',etiqueta:'Marca',valor:false,campocomentario:'marcacom', comentario:'',visible:true},
            {nombre:'modelo',etiqueta:'Modelo',valor:false,campocomentario:'modelocom', comentario:'',visible:true},
            {nombre:'carroceria',etiqueta:'Carrocería',valor:false,campocomentario:'carroceriacom', comentario:'',visible:true},
            {nombre:'categoria',etiqueta:'Categoría',valor:false,campocomentario:'categoriacom', comentario:'',visible:true},
            {nombre:'seriechasis',etiqueta:'Serie de chasis',valor:false,campocomentario:'seriechasiscom', comentario:'',visible:true},
            {nombre:'seriemarcamotor',etiqueta:'Serie y marca de motor',valor:false,campocomentario:'seriemarcamotorcom', comentario:'',visible:true},
            {nombre:'radiocomunicacion',etiqueta:'Radio de comunicación',valor:false,campocomentario:'radiocomunicacioncom', comentario:'',visible:true},
            {nombre:'autorradio',etiqueta:'Autorradio',valor:false,campocomentario:'autorradiocom', comentario:'',visible:true},
            {nombre:'llantarepuesto',etiqueta:'Llantas de repuesto',valor:false,campocomentario:'llantarepuestocom', comentario:'',visible:true},
            {nombre:'camara',etiqueta:'Cámara',valor:false,campocomentario:'camaracom', comentario:'',visible:true},
            {nombre:'tablet',etiqueta:'Tablet',valor:false,campocomentario:'tabletcom', comentario:'',visible:true},
            {nombre:'dispositivoseguridad',etiqueta:'Dispositivos de seguridad',valor:false,campocomentario:'dispositivoseguridadcom', comentario:'',visible:true},
            {nombre:'estadounidad',etiqueta:'ESTADO DE LA UNIDAD ',valor:false,campocomentario:'estadounidadcom', comentario:'',visible:true},
            {nombre:'cajaherramientas',etiqueta:'Caja de herramientas',valor:false,campocomentario:'cajaherramientascom', comentario:'',visible:true},
            {nombre:'jaulaantivuelco',etiqueta:'Jaula antivuelco',valor:false,campocomentario:'jaulaantivuelcocom', comentario:'',visible:true},
            {nombre:'carriboy',etiqueta:'Carriboy ',valor:false,campocomentario:'carriboycom', comentario:'',visible:true},
            {nombre:'baranda',etiqueta:'Baranda',valor:false,campocomentario:'barandacom', comentario:'',visible:true},
            {nombre:'mamparon',etiqueta:'Mamparón',valor:false,campocomentario:'mamparoncom', comentario:'',visible:true},

            {nombre:'ancho',etiqueta:'Ancho',valor:false,campocomentario:'anchocom', comentario:'',visible:true},
            {nombre:'longitud',etiqueta:'Longitud',valor:false,campocomentario:'longitudcom', comentario:'',visible:true},
            {nombre:'alto',etiqueta:'Alto',valor:false,campocomentario:'altocom', comentario:'',visible:true},
            {nombre:'tiposuspencion',etiqueta:'Tipo de suspensión ',valor:false,campocomentario:'tiposuspencioncom', comentario:'',visible:true},
            {nombre:'tipotornamesa',etiqueta:'Tipo de tornamesa',valor:false,campocomentario:'tipotornamesacom', comentario:'',visible:true}

        ];
        let ocultar=[];
        //tipo de unidad
        if(camion.includes(tipovehiculo)){
            console.log('es camion');
            setTipoPlanilla(1);
            ocultar=['resoluciongerencial','certificadobonificacion','certificadooperatividad','cajaherramientas','jaulaantivuelco','carriboy','baranda','mamparon'];

        }else if(remolcado.includes(tipovehiculo)){
            console.log('es remolcador');
            setTipoPlanilla(2);
            ocultar=['llantarepuesto','cajaherramientas','jaulaantivuelco','carriboy','baranda','mamparon'];

        }else if(semiremolque.includes(tipovehiculo)){
            console.log('es semiremolque');
            setTipoPlanilla(3);
            ocultar=['soat','seriemarcamotor','radiocomunicacion','autorradio','camara','tablet','dispositivoseguridad','cajaherramientas','jaulaantivuelco','carriboy','tipotornamesa'];
        }else{
            console.log('es equipo liviano');
            setTipoPlanilla(4);
            ocultar=['revisiontecnica6','resoluciondirectoral','resoluciongerencial','iqbf','certificadomatpel','certificadobonificacion','certificadooperatividad','estadounidad','baranda','mamparon','tiposuspencion','tipotornamesa'];
        }

        const requestOptions = {
            method: "GET",
            headers: {"Content-Type": "application/json"}
        };
        fetch(process.env.REACT_APP_INVENTARIO_API_URL +"api/periodo/periodoActivo",requestOptions)
        .then(response => response.json())
        .then(res => {
            console.log(res);
            if(res.rest==1){
                setNombrePeriodo(res.periodo.nombre);
                setPeriodo(res.periodo.idperiodo);
                const requestOptions = {
                    method: "GET",
                    headers: {"Content-Type": "application/json"}
                };
                fetch(process.env.REACT_APP_INVENTARIO_API_URL +"api/inventario/obtenerInventarioActualxidActivo/"+idactivo,requestOptions)
                .then(response => response.json())
                .then(res => {
                    console.log(res);

                    if(res.rest==1){

                        setInventario(res.inventario);
                        setObservacion(res.inventario.observacion);
                        if(res.inventario.imagen1!=='' && res.inventario.imagen1!==null ){
                            setUrlImagen1(process.env.REACT_APP_INVENTARIO_DIR_IMAGEN + res.inventario.imagen1);
                        }
                        if(res.inventario.imagen2!=='' && res.inventario.imagen2!==null ){
                            setUrlImagen2(process.env.REACT_APP_INVENTARIO_DIR_IMAGEN + res.inventario.imagen2);
                        }
                        if(res.inventario.imagen3!=='' && res.inventario.imagen3!==null ){
                            setUrlImagen3(process.env.REACT_APP_INVENTARIO_DIR_IMAGEN + res.inventario.imagen3);
                        }
                        if(res.inventario.imagen4 !== '' && res.inventario.imagen4 !== null){
                            setUrlImagen4(process.env.REACT_APP_INVENTARIO_DIR_IMAGEN + res.inventario.imagen4);
                        }
                        //console.log(imagenurl);

                        if(res.inventario.detalle!==null){
                            //cargar planilla
                            if(res.inventario.detalle.tipoplanilla == 1){
                                setTipoPlanilla(1);
                                ocultar=['resoluciongerencial','certificadobonificacion','certificadooperatividad','cajaherramientas','jaulaantivuelco','carriboy','baranda','mamparon'];
                    
                            }else if(res.inventario.detalle.tipoplanilla == 2){
                                setTipoPlanilla(2);
                                ocultar=['llantarepuesto','cajaherramientas','jaulaantivuelco','carriboy','baranda','mamparon'];
                    
                            }else if(res.inventario.detalle.tipoplanilla == 3){
                                setTipoPlanilla(3);
                                ocultar=['soat','seriemarcamotor','radiocomunicacion','autorradio','camara','tablet','dispositivoseguridad','cajaherramientas','jaulaantivuelco','carriboy','tipotornamesa'];
                            }else{
                                setTipoPlanilla(4);
                                ocultar=['revisiontecnica6','resoluciondirectoral','resoluciongerencial','iqbf','certificadomatpel','certificadobonificacion','certificadooperatividad','estadounidad','baranda','mamparon','tiposuspencion','tipotornamesa'];
                            }

                            //cargar detalle
                            camposplanilla.map(({nombre,campocomentario},index)=>{
                                camposplanilla[index].valor= (res.inventario.detalle[nombre] == null)?false: res.inventario.detalle[nombre];
                                camposplanilla[index].comentario = (res.inventario.detalle[campocomentario] == null)?'':res.inventario.detalle[campocomentario];                        
                            })

                        }

                        
                        
                    }
                    
                    ocultar.map((campo,i)=>{
                        //console.log(campo);
                        camposplanilla.map(({nombre},index)=>{
                            if(campo==nombre){
                                camposplanilla[index].visible=false;
                            }                            
                        })
            
                    })
                    console.log('campos ocultos');        


                    setPlanilla(camposplanilla);
                    setCargando(false);   
                });



                
            }
        });
    },[]);

    const onObserbacionChange = e => setObservacion(e.target.value);
    const onTipoPlantillaChange = e =>{
        setTipoPlanilla(e.target.value);
        //actualizar plantilla

        let actualizarplanilla = [...planilla];
        actualizarplanilla.map(({nombre},index)=>{
            actualizarplanilla[index].visible=true;                          
        })


        let ocultar=[];
        if(e.target.value == 1){
            ocultar=['resoluciongerencial','certificadobonificacion','certificadooperatividad','cajaherramientas','jaulaantivuelco','carriboy','baranda','mamparon'];
        }else if(e.target.value == 2){
            ocultar=['llantarepuesto','cajaherramientas','jaulaantivuelco','carriboy','baranda','mamparon'];
        }else if(e.target.value == 3){
            ocultar=['soat','seriemarcamotor','radiocomunicacion','autorradio','camara','tablet','dispositivoseguridad','cajaherramientas','jaulaantivuelco','carriboy','tipotornamesa'];
        }else{
            ocultar=['revisiontecnica6','resoluciondirectoral','resoluciongerencial','iqbf','certificadomatpel','certificadobonificacion','certificadooperatividad','estadounidad','baranda','mamparon','tiposuspencion','tipotornamesa'];
        }

        
        ocultar.map((campo,i)=>{
            //console.log(campo);
            actualizarplanilla.map(({nombre},index)=>{
                if(campo==nombre){
                    actualizarplanilla[index].visible=false;
                }                            
            })

        })
        setPlanilla(actualizarplanilla);
        console.log('campos ocultos'); 
    }

    const onSeleccionPlanilla = (index)=>{
        let actualizarplanilla = [...planilla];
        actualizarplanilla[index].valor = !actualizarplanilla[index].valor
        setPlanilla(actualizarplanilla);
        //console.log(planilla);
    }

    const onComentarioPlanilla = e =>{
        let actualizarplanilla = [...planilla];
        
        let index = e.target.dataset.index;
        actualizarplanilla[index].comentario=e.target.value;
        setPlanilla(actualizarplanilla);
        //console.log(planilla);
    }


    const handleGuardarInventario = e =>{
        e.preventDefault();
        const data = {idactivo,codigo,periodo,observacion,usuario,tipo,tipoplanilla };
        if(tipo=='flota'){
            planilla.map(({nombre,valor,campocomentario,comentario},index)=>{
                data[nombre] = valor;
                data[campocomentario] = comentario;
            })
        }
        console.log(data);
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(data)
        };
        fetch(process.env.REACT_APP_INVENTARIO_API_URL +"api/inventario/store",requestOptions)
        .then(response => response.json())
        .then(res =>{
            console.log(res);
            if(res.rest===1){
                console.log("Se registro en inventario");
                console.log(res);

                if(imagen1!=null || imagen2!=null || imagen3!=null || imagen4!=null ){
                    const file = new FormData();
                    file.append("file1",imagen1);
                    file.append("file2",imagen2);
                    file.append("file3",imagen3);
                    file.append("file4",imagen4);
                    file.append("idinventario",res.inventario.idinventario);
                                    
                    const requestimagen = {
                        method:"POST",
                        body: file
                    };
                    fetch(process.env.REACT_APP_INVENTARIO_API_URL +"api/inventario/imagen",requestimagen)
                    .then(response => response.json())
                    .then(res =>{
                        console.log(res);
                        navigate('/activo/?idactivo='+idactivo+'&tipo='+tipo+'&codigo='+codigo);
                    });
                }else{
                    navigate('/activo/?idactivo='+idactivo+'&tipo='+tipo+'&codigo='+codigo);
                }
            }else
            {
                console.log("acceso incorrecto vuelve a intentar");
                
            }
        });
        
    }


    if(cargando){
        return(
            <div className="container">
                <h4 className="text-center">No se tiene un periodo activo para realizar inventario</h4>
                <Link to ={`/activo/?idactivo=${idactivo}&tipo=${tipo}&codigo=${codigo}`}>Regresar a busqueda de activos</Link>
            </div>
        );
    }

    return(
        <div className="container InventarioFlota">
            <br/>
            <h3 className="mb-3 text-center">Inventario</h3>
            <br/>
       
            <div>
                <h4>Id Activo: {idactivo} - Código: {codigo}</h4>  
                <div className="text-end">
                    <Link to ={`/activo/?idactivo=${idactivo}&tipo=${tipo}&codigo=${codigo}`} className="btn btn-light">Regresar</Link>
                </div>                  
            </div>

            <div className="mb-3">
            <label className="col-form-label">Periodo</label>
            <label className="form-control">{nombreperiodo}</label>
            </div>
            
            <div className="md-3" hidden={tipo==='flota'?0:1}>
                <div className="row">
                    <div className="col-3">
                    <label className="form-label">Planilla de inventario :</label> 

                    </div>
                    <div className="col-9">
                    <select className=" col form-select" defaultValue={tipoplanilla} onChange={onTipoPlantillaChange}>
                        <option value={1}>Camion</option>
                        <option value={2}>Remolcador</option>
                        <option value={3}>Semiremolque</option>
                        <option value={4}>Equipo liviano</option>
                    </select>

                    </div>
                    <br/>
                    <br/>               
                </div>
                
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            
                            { planilla.map(({etiqueta,nombre,valor,campocomentario,comentario,visible},index)=>{
                                if(visible===true){
                                return(

                                    <div className="row">
                                            <label className="col-10 col-md-5 form-label">{etiqueta}: </label>
                                            <input type="checkbox" name={nombre} value={valor} checked={valor} className="col-2 col-md-1" onChange={()=>onSeleccionPlanilla(index)} />
                                        
                                            <span className="col-12 col-md-6">
                                                <input type="text" name={campocomentario} value={comentario} className="form-control" data-index={index} onChange={onComentarioPlanilla} ></input>
                                                
                                            </span>
                                            <hr className="m-2"/>
                                    </div>
                                );
                                }
                            }
                            ) }
                            
                        </div>
                    </div>
                </div>

            </div>
            
            
            
            
            <div className="mb-3">
            <label className="col-form-label">Observacion: </label>
            <textarea className="form-control" rows="3" value={observacion} onChange={onObserbacionChange}></textarea>
            </div>

            <div className="row"> 
                <div className="col-12 col-sm-6 col-md-3 p-2">
                    
                        <div className="file-select" id="src-file1" >
                            <input type="file" name="src-file1" aria-label="Archivo" onChange={onSelectImagen1}/>
                        </div>
                        <br/>
                        <br/>
                        <div className=" col-8 col-md-10">
                            <img src={urlimagen1} className="col-12 imgprev img-thumbnail" alt=""></img>
                        </div>
                        
                </div>

                <div className="col-12 col-sm-6 col-md-3 p-2">
                        <div className="file-select" id="src-file2" >
                            <input type="file" name="src-file2" aria-label="Archivo" onChange={onSelectImagen2}/>
                        </div>
                        <br/>
                        <br/>
                        <div className="col-8 col-md-10">
                            <img src={urlimagen2} className="col-12 imgprev img-thumbnail" alt=""></img>
                        </div>
                        
                </div>

                    <div className="col-12 col-sm-6 col-md-3 p-2">
                        <div className="file-select " id="src-file3" >
                            <input type="file" name="src-file3" aria-label="Archivo" onChange={onSelectImagen3} />
                        </div>
                        <br/>
                        <br/>
                        <div className="col-8 col-md-10">
                            <img src={urlimagen3} className="col-12 imgprev img-thumbnail" alt=""></img>
                        </div>
                        
                    </div>
                    <div className="col-12 col-sm-6 col-md-3 p-2">
                        <div className="file-select " id="src-file4" >
                            <input type="file" name="src-file4" aria-label="Archivo" onChange={onSelectImagen4} />
                        </div>
                        <br/>
                        <br/>
                        <div className="col-8 col-md-10">
                            <img src={urlimagen4} className="col-12 imgprev img-thumbnail" alt=""></img>
                        </div>
                        
                    </div>
                </div>







            <br/>

                            
            <div className="text-end">
                <br/>
                <button type="button" className="col-12 col-md-6 col-lg-4 btn btn-primary" onClick={handleGuardarInventario} >Guardar</button>
                
            </div>

            <br/>
            <br/>
            <br/>
            <br/>
            <br/>

        </div>
    )
}